import React from "react"

const FirstSection = () => {
    return (
        <section className="first">
            <div className="left">
                <h1 className="title blue-font">
                    O melhor <span className="yellow-font">presente.</span><br/>No seu tempo e de onde você estiver
                </h1>
                <p className="description blue-font">
                    Gifthy é o seu assistente para presentear. Foi feito para simplificar a compra de presentes para <span className='font-bold'>amigos, parentes, colegas</span> e quem você quiser.
                </p>
            </div>
        </section>
    )
}

export default FirstSection
